import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// vendor components & lib
import { graphql, StaticQuery } from 'gatsby';

// components
import Header from 'components/common/header';
import Layout from 'components/common/layout';
import ServiceBot from 'components/service-bot';

// styles
import 'stylesheets/subsctibe/checkout.scss';

// component function
class ServicebotCheckoutPage extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  getCurrentPlan() {
    const { payment_id: paymentId } = queryString.parse(
      this.props.location.search,
    );
    return ~~paymentId || null;
  }

  render() {
    const paymentId = this.getCurrentPlan();

    return (
      <Layout title="Login">
        <main>
          <Header styleType="dark" showLogo />
          <ServiceBot.Checkout
            paymentStructureTemplateId={paymentId}
            setPassword
            forceCard
          />
        </main>
      </Layout>
    );
  }
}

/*
function TopSection() {
  <div className="faq_top-section">
            <Header
              className="faq_top-section_header"
              styleType="semi-transparent"
              showLogo
            />

            <ImageAsBackground image={imageChildFluid} />

            <div className="faq_top-section_content">
              <h1 className="faq_top-section_title">Checkout</h1>
              <p className="faq_description">How can we help you</p>
            </div>

            <div className="faq_top-section_overlay" />
          </div>
}
*/

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "faq-page.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ServicebotCheckoutPage data={data} {...props} />}
  />
);
